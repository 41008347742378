import { useEffect, useCallback, useState, useContext, useRef } from "react";
import { ChartContext } from "../Canvas/ChartContext"; 

import * as d3 from "d3";
import { depthGen } from "../../../utils/depth";


const xLabelWidth = 160;
const xLabelHeight = 20;

const XLabel = ({ xPos }) => {
  const { margins, height, width, xScale, xFormat } = useContext(ChartContext);

  const textEl = useRef();
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
      if (textEl.current) {
        setTextWidth(textEl.current.getComputedTextLength() + 5)
      }
    }, [textEl, xPos])

  if (!xScale) return;

  let left = -textWidth/2;

  if (left < -xPos) {
    left = -xPos
  } else if (left < (xPos  - width)) {
    left = width - textWidth - xPos
  }
  const right = textWidth + left;

  const fmt = xFormat || xScale.tickFormat();

  // commented out toFixed because it doesn't work with dates
  const label = fmt(xScale.invert(xPos))//.toFixed(2)); 

  const poly = `0,0 -2, -3 -8,-3 ${left},-3 ${left},${-xLabelHeight} ${right},${-xLabelHeight} ${right},-3 2,-3`;

  return <g transform={`translate(${xPos},${margins.top})`}
            style={{display: xPos ? 'block' : 'none'}}
            >
            <line stroke={"orange"} 
              y1={height - margins.bottom - margins.top} 
            />
            <polygon 
              points={poly}
              fill="white"
              stroke="orange"
            />
            <text 
              ref={textEl}  
              textAnchor="middle" 
              stroke="orange" 
              x={left + textWidth/2} y={-7}>
                {label}
            </text>
         </g>
}

export const YLabel = ({ yPos }) => {
  const { margins, height, width, yScale, yFormat } = useContext(ChartContext);

  if (!yScale) return;

  const yExtent = yScale.domain();
  let yCrosshairSigFigs = 2;

  if (Math.abs(yExtent[0])+Math.abs(yExtent[1]) < 10) {
      yCrosshairSigFigs = 3
  }

  const poly = "0, 0 3, -2, 3,-8 64,-8 64,8 3,8 3,2"

  const label = yFormat(yScale.invert(yPos).toFixed(yCrosshairSigFigs));

  return <g transform={`translate(${width - margins.right}, ${yPos})`}
            style={{display: yPos ? 'block' : 'none'}}
            >
            <line stroke={"red"} 
              x1={-width + margins.left + (margins.right + 5)} />
            <polygon 
              points={poly}
              fill="white"
              stroke="red"
            />
            <text dominantBaseline="central" stroke="red" x={5}>{label || ""}</text>
         </g>
}

export const CrossHairs = ({ excludeSetXOverlay }) => {
    const [ cursor, setCursor ] = useState();
    
    const { onCursorMove, height, width, margins, 
        xScale, yScale, traces, setXOverlay } = useContext(ChartContext);

    const h = height - margins.bottom - margins.top;
    const w = width - margins.right - margins.left;
    const bbox = cursor?.target.getBoundingClientRect()
    const x = cursor?.clientX - bbox?.x + margins.left;
    const y = cursor?.clientY - bbox?.y + margins.top;

    const onMove = useCallback((e) => {
        e.preventDefault()
        setCursor(e)

        const bbox = e.target.getBoundingClientRect()
        const x = e.clientX - bbox.x + margins.left;
        const y = e.clientY - bbox.y + margins.top;


        if (xScale && yScale) onCursorMove({x: xScale.invert(x), y: yScale.invert(y)})
    }, [x, y, traces, xScale, yScale])

    const onWheel = useCallback((e) => {
        console.log("zooming by mousewheel disabled", e.clientX, e.clientY)
    }, []);

    const onDoubleClick = (e) => {
        const bbox = e.target.getBoundingClientRect()
        const x = e.clientX - bbox.x + margins.left;
        if (!excludeSetXOverlay){
          setXOverlay(xScale.invert(x))
        }
        
    }


    return <>
        { cursor ? <> 
            <XLabel xPos={x} />
            <YLabel yPos={y} />
        </>
        : null }
        { w > 0 && h > 0 ? <rect 
            x={margins.left} 
            y={margins.top} 
            width={w} 
            height={h} 
            fill="none" 
            pointerEvents={"visible"}
            onMouseMove={onMove}
            onMouseLeave={e => {
                setCursor(null)
                onCursorMove(null)
            }}
            onWheel={onWheel}
            onDoubleClick={onDoubleClick}
         /> : null}
    </>
}