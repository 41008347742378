import { useEffect, useState, useCallback, useContext } from "react";

import * as d3 from "d3";
import dayjs from "dayjs";

import { ChartContext } from "../Canvas/ChartContext";

import { TraceSelector } from "./TraceSelector";

import { Chart } from "../Chart";

import { WellContext } from "../../../api/well";

import { XBrush } from "./Brush";

import { Spin } from 'antd';

import debounce from 'lodash/debounce';



import './selector.css';

// Margins from ChartWithOverlay
const defaultMargins = {
    top: 20,
    bottom: 30,
    left: 60,
    right: 50 
}

export const TimeSelector = ({
    margins=defaultMargins,
    noBrush,
    onBrush,
    current,
    selected,
    setSelectedTrace,
    onTimeDrag,
    yUnits,
    timeSelectorLossLims,
    onRangeUpdate,
    showDatePicker,
    yLim,
    children,
    view,
    activeTab,
    isLoading,
    setIsLoading,
    loadedTimeRanges,
    setLossQueryTimestamps
}) => {

    // Check if loadedTimeRanges is null or has a length of 0 on page load
    const isTimeRangesEmpty = !loadedTimeRanges || loadedTimeRanges.length === 0;

    // loaded time range if user loads a time range from the date picker from outside the time selector
    const [userLoadedTimeRange, setUserLoadedTimeRange] = useState(
        isTimeRangesEmpty ? [ ] : loadedTimeRanges
    );

    const [userLoadedRange, setUserLoadedRange] = useState(
        isTimeRangesEmpty ? [dayjs().add("-1", "week"), dayjs()] : loadedTimeRanges
    );

    //set the ranges to the user loaded ranges for the TFO Time Selector
    useEffect(() => {
        if (view === "TFO") {
            setUserLoadedTimeRange( isTimeRangesEmpty ? [0, 0] : loadedTimeRanges);
            setTimeRange(isTimeRangesEmpty ? [0, 0] : loadedTimeRanges);
            setUserLoadedRange(isTimeRangesEmpty ? [dayjs().add("-1", "week"), dayjs()] : loadedTimeRanges);
            setRange(isTimeRangesEmpty ? [dayjs().add("-1", "week"), dayjs()] : loadedTimeRanges.map(date => dayjs(date)));
        }
    }, [loadedTimeRanges]);

    const [ timeRange, setTimeRange ] = useState(userLoadedTimeRange);

    const [ range, setRange ] = useState(userLoadedRange);

    const wellAPI = useContext(WellContext);

    const { fetchLatest, queryTimestamps } = wellAPI;

    useEffect(() => {
        queryTimestamps.sendRequest({start: range[0].toISOString(), end: range[1].toISOString()})
        // console.log("range times for loss", range[0].toISOString(), range[1].toISOString())
        if (view === "loss"){
            setLossQueryTimestamps([range[0].toISOString(), range[1].toISOString()]);
        }


        const cancel = onRangeUpdate && onRangeUpdate(range);
        // console.log("range in time selector", range)
        return () => {
            queryTimestamps.cancel();
            cancel && cancel();
        }
        
    }, [range]);

    useEffect(() => {
      const latest = dayjs(wellAPI.fetchLatest.data?.timestamp);

      if ( latest.isBefore(range[0]) ) {
          setRange([latest.add(-1, "day"), latest]);
      }

    }, [fetchLatest.data, queryTimestamps.data])


    // If the range changes, check if selected is within the range, if not set selected to last value in range
    useEffect(() => {
        if (setSelectedTrace && selected && loadedTimeRanges && (selected < range[0] || selected > range[1])) {
            
            setSelectedTrace(range[1]);
            // view === "TFO" ? setSelectedTraceTFO(range[1]):setSelectedTrace(range[1]);
        }
    }, [range, selected])


    useEffect(() => {
        if (current?.length) {
            setTimeRange([current[0].xData[0], current[0].xData[current[0].xData.length - 1]])

            // const min = Math.floor(Math.min(...current.map(t => Math.min(...t.yData)))/5)*5;
            // const max = Math.ceil(Math.max(...current.map(t => Math.max(...t.yData)))/5)*5;

            // setYLim([min, max])
        }
    }, [current])

    return <>
            <div className="selectorButtons">
                <TraceSelector 
                    range={range}
                    setRange={(r) => setRange(r)}
                    traceSelected={onTimeDrag}
                    selectedLineTrace={selected}
                    showDatePicker={showDatePicker}
                    activeTab={activeTab}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                >
                    {children}
                </TraceSelector>
            </div>
            <div className="timeSelector">
                <Chart
                    margins={margins}
                    xScaleType="time"
                    title="Time Range"
                    // xLim={timeRange}
                    xLim={range}
                    yLim={yUnits === "dB" ? timeSelectorLossLims : yLim}
                    xUnit=""
                    yUnit={yUnits === "dB" ? "dB" : "℃"}
                    onDrag={onTimeDrag}
                    traces={current}
                    useCrosshairs={false}
                    isLoading={isLoading}
                    >
                { selected && <VerticalOverlayLine x={new Date(selected)} onDrag={onTimeDrag} current={current}/> }
                {/* { !noBrush && <XBrush brushChange={handleBrushChange} onBrush={onBrush} brushExtents={userSelectedRangeLoaded ? userLoadedTimeRange : timeRange} /> } */}
                { !noBrush && <XBrush onBrush={onBrush} brushExtents={timeRange} /> }
                </Chart>
            </div>
    </>
}

const VerticalOverlayLine = ({
    onDrag,
    x,
    current
}) => {
    const { height, width, margins, xScale } = useContext(ChartContext);

    const [drag, setDrag ] = useState(false);

    const [ , setDebounce] = useState();

    const [dispX, setDispX] = useState();

    const h = height - margins.bottom;
    const b = margins.top;

    // const [ handle, setHandle ] = useState();

    useEffect(() => {
        setDispX(xScale(x));
    }, [x, xScale])

    const onMouseDown = e => {
        const el = e.target;
        el.setPointerCapture(e.pointerId)
        setDrag(true)
    }

    const onMouseMove = useCallback(e => {
        e.preventDefault()
        if (!current?.length) return;

        if (drag) {
            const times = current[0].xData;

            const bbox = e.target.parentElement.getBoundingClientRect()
            let xPos = e.clientX - bbox.x;

            const d = xScale.invert(xPos);
            let i = d3.bisect(times, d);

            if (xPos <= margins.left) {
                i = 0; 
                xPos = margins.left;
            }

            if (xPos >= width - margins.right) {
                i = times.length - 1;
                xPos = width - margins.right;
            }
            setDebounce(c => {
                clearTimeout(c)
                return setTimeout(() => {
                    onDrag && onDrag(times[i])
                }, 300) // debounce time prevents too many requests when bar is dragged quickly
            })

            setDispX(xScale(times[i]))

            // setHandle({x: times[i], xPos: xPos})
        }
    }, [drag, current, xScale, onDrag]);

    const onMouseUp = e => {
        e.preventDefault()
        setDrag(false)
        const el = e.target;
        el.releasePointerCapture(e.pointerId)
    }

    return (
        <>
            <line x1={dispX} x2={dispX} y1={b} y2={h} stroke="black" strokeWidth="10" /> 

            {/* Invisible Wider Line for Easier Interaction - Hit Area */}
            <line
                x1={dispX} x2={dispX} y1={b} y2={h}
                strokeWidth="25" 
                stroke="rgba(0,0,0,0)" // Transparent
                onPointerDown={onMouseDown}
                onPointerMoveCapture={onMouseMove}
                onPointerUp={onMouseUp}
            />
        </>
    );
}

//Below is a somewhat simpler version of the TimeSelector component that is used in the TFO tab, 
//to prevent interference with the TimeSelector component used in the other tabs
export const TimeSelectorTFO = ({
    margins=defaultMargins,
    noBrush,
    onBrush,
    current,
    selected,
    onTimeDrag,
    yUnits,
    timeSelectorLossLims,
    onRangeUpdate,
    showDatePicker,
    yLim,
    children,
    view,
    activeTab,
    isLoading,
    setIsLoading,
    loadedTimeRanges,
}) => {

    // Check if loadedTimeRanges is null or has a length of 0 on page load
    const isTimeRangesEmpty = !loadedTimeRanges || loadedTimeRanges.length === 0;

    // loaded time range if user loads a time range from the date picker from outside the time selector
    const [userLoadedTimeRange, setUserLoadedTimeRange] = useState(
        isTimeRangesEmpty ? [ ] : loadedTimeRanges
    );

    const [userLoadedRange, setUserLoadedRange] = useState(
        isTimeRangesEmpty ? [dayjs().add("-1", "week"), dayjs()] : loadedTimeRanges
    );

    //set the ranges to the user loaded ranges for the TFO Time Selector
    useEffect(() => {
        if (view === "TFO") {
            setUserLoadedTimeRange( isTimeRangesEmpty ? [0, 0] : loadedTimeRanges);
            setTimeRange(isTimeRangesEmpty ? [0, 0] : loadedTimeRanges);
            setUserLoadedRange(isTimeRangesEmpty ? [dayjs().add("-1", "week"), dayjs()] : loadedTimeRanges);
            setRange(isTimeRangesEmpty ? [dayjs().add("-1", "week"), dayjs()] : loadedTimeRanges.map(date => dayjs(date)));
        }
    }, [loadedTimeRanges]);


    const [ timeRange, setTimeRange ] = useState(userLoadedTimeRange);


    const [ range, setRange ] = useState(userLoadedRange);

    const wellAPI = useContext(WellContext);

    const { fetchLatest, queryTimestamps } = wellAPI;

    useEffect(() => {
        queryTimestamps.sendRequest({start: range[0].toISOString(), end: range[1].toISOString()})

        const cancel = onRangeUpdate && onRangeUpdate(range);
        // console.log("range in time selector", range)
        return () => {
            queryTimestamps.cancel();
            cancel && cancel();
        }
        
    }, [range]);

    useEffect(() => {
      const latest = dayjs(wellAPI.fetchLatest.data?.timestamp);

      if ( latest.isBefore(range[0]) ) {
          setRange([latest.add(-1, "day"), latest]);
      }

    }, [fetchLatest.data, queryTimestamps.data])




    useEffect(() => {
        if (current?.length) {
            // console.log("current", current)
            setTimeRange([current[0].xData[0], current[0].xData[current[0].xData.length - 1]])

            // const min = Math.floor(Math.min(...current.map(t => Math.min(...t.yData)))/5)*5;
            // const max = Math.ceil(Math.max(...current.map(t => Math.max(...t.yData)))/5)*5;

            // setYLim([min, max])
        }
    }, [current])

    //created this function because the yData(loss) was reversed in the current array
    //currentReversed is the reversed yData used in the TimeRange Chart - used in Health Status tab
    //-VLAD
    const [currentReversed, setCurrentReversed] = useState([]);
    const reverseYData = (data) => {
        const reversedData = data.map(item => {
            return {...item, yData: [...item.yData].reverse()};
        });
        setCurrentReversed(reversedData);
    };

    useEffect(() => {
        if (current && current.length > 0) {
            reverseYData(current);
            setIsLoading(false);
        }
    }, [current]);


    return <>
            <div className="selectorButtons">
                <TraceSelector 
                    range={range}
                    setRange={(r) => setRange(r)}
                    selectedLineTrace={selected}
                    showDatePicker={showDatePicker}
                    activeTab={activeTab}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                >
                    {children}
                </TraceSelector>
            </div>
            <div className="timeSelector">
                <Chart
                    margins={margins}
                    xScaleType="time"
                    title="Time Range"
                    xLim={range}
                    yLim={yUnits === "dB" ? timeSelectorLossLims : yLim}
                    xUnit=""
                    yUnit={yUnits === "dB" ? "dB" : "℃"}
                    onDrag={onTimeDrag}
                    traces={view === 'loss'? currentReversed : current} //loss timerange chart uses currentReversed
                    useCrosshairs={false}
                    isLoading={isLoading}
                    >
                { !noBrush && <XBrush onBrush={onBrush} brushExtents={timeRange} /> }
                </Chart>
            </div>
    </>
}