import React, { useState, useEffect, useContext, useRef } from 'react';
import { Tabs, Button, Modal, Select, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './style.css'
import WellSchematicLayout from './WellSchematicLayout';
import { WellContext } from "../../api/well";

import { transformAPISchemaSections } from "../../utils/transformAPISchemaSections";
import { depthLimits } from "../../utils/depth";
import convertTrace from "../Charts/convert";
import { useDBWellApi } from "../../api/DBWellApi";

const { TabPane } = Tabs;
const { Option } = Select;

/* 
This component is a wrapper for the WellSchematic component. It handles having multiple tabs created by user where each tab 
is a separate WellSchematic component. It also handles the logic for adding and deleting tabs. 
*/

function WellSchema({ onSchemaUpdate }) {

  //states for tabs and modal
  const [ tabs, setTabs ] = useState([]);
  const [ activeKey, setActiveKey ] = useState('0');
  const [ showModal, setShowModal ] = useState(false);
  const [ newLayerName, setNewLayerName ] = useState('');
  const [ deleteTab, setDeleteTab ] = useState(false);
  const [ updateTab, setUpdateTab ] = useState(null);

  //states for managing schemaSections  information
  const [ schemaSections, setSchemaSections] = useState([]);
  const [ depthLim, setDepthLim ] = useState();
  const [ currentTrace, setCurrentTrace ] = useState();
  const [ availableLayers, setAvailableLayers ] = useState([]); // from the layers table

  const { deleteDOI } = useDBWellApi(); // Using custom hook here
  
  // Context for well data
  const wellAPI = useContext(WellContext);

  const initialLoad = useRef(true);  // Declare the ref


  // this effect will update schemaSections when the layer is successfully stored in DB for the well, 
  //to make the layer name uneditable
  useEffect(() => {
    if (updateTab) {
        const updatedSchemaSections = schemaSections.map(section => ({
            ...section,
            storedInDB: true
        }));

        setSchemaSections(updatedSchemaSections);
    }
}, [updateTab]);

  // function for adding a new tab/layer
  const handleAddTab = () => {
    const newTabLayerName = `Select Layer Name`;

    const newTab = { 
        layerName: newTabLayerName, 
        isEnabled: true, 
        sections: [], 
        storedInDB: false
    };

    setTabs(prevTabs => [...prevTabs, newTab]);
    setSchemaSections(prevSections => [...prevSections, { layerName: newTabLayerName, sections: [], storedInDB: false }]);
    setActiveKey(newTabLayerName);
};


  //changes tab name
  const handleTabNameChange = (currentLayerName, newValue) => {
    if (newValue === 'Add New') {
        setShowModal(true);
        return;
    }

    const updatedTabs = tabs.map(tab => {
        if (tab.layerName === currentLayerName) {
            return { ...tab, layerName: newValue, storedInDB: false };
        }
        return tab;
    });

    // Update the corresponding schemaSection's name as well
    const updatedSchemaSections = schemaSections.map(section => {
        if (section.layerName === currentLayerName) {
            return { ...section, layerName: newValue, isEnabled: true };
        }
        return section;
    });

    setTabs(updatedTabs);
    setSchemaSections(updatedSchemaSections);

    if (activeKey === currentLayerName) {
        setActiveKey(newValue); // Update the activeKey if the current active tab is renamed
    }
};


  //handle existing layer names returned by API
  const getExistingLayerNames = () => {
    return tabs.map(tab => tab.layerName);
  };

  // Load the latest trace and update currentTrace to start
  useEffect(() => {
    wellAPI.fetchLatest.data && setCurrentTrace(convertTrace(wellAPI.fetchLatest.data));
  }, [])

  // Update depthLimits and depthExtents
  useEffect(() => {
    if (currentTrace) {
        let newDl = depthLimits(currentTrace)

        if (!depthLim) {
            setDepthLim(newDl)
        } 
    }
  }, [currentTrace, depthLim])

  //fetching data from API
  useEffect(() => {
    wellAPI.fetchDepthOfInterest.sendRequest();
  }, []); 

  //Effect will retrieve data from API and transform it into the schemaSections format necessary for WellSchematic
  useEffect(() => {

    if (wellAPI.fetchDepthOfInterest.data && depthLim) {
      const transformedSections = transformAPISchemaSections(
          wellAPI.fetchDepthOfInterest.data.allDepthOfInterest_DTO, depthLim
      ).map(section => ({
          ...section,
          storedInDB: true // Ensure this is set to true for API-fetched data
      }));

      const availableLayers = wellAPI.fetchDepthOfInterest.data.allDepthOfInterestLayer?.map((section) => {
        return section.doiLayerLabel;
        });
      setAvailableLayers(availableLayers);
    
      setSchemaSections(transformedSections);
      // Update tabs only with API-fetched data
      setTabs(transformedSections);
  }


  }, [depthLim, wellAPI.fetchDepthOfInterest.data]);

  //callback function  designed for 'Add Section' button to refresh schemaSections based on DB data when section gets added or deleted.
  // not used for now - may revisit later an option to add sections from DB
  
  // const updateSchemaSections = async () => {
  //   // console.log("updateSchemaSections called")
  //   if (depthLim) {
  //       try {
  //           await wellAPI.fetchDepthOfInterest.sendRequest();
  //           const newData = wellAPI.fetchDepthOfInterest.data;
  //           // console.log("newData:", newData)
  //           if (newData) {
  //               const transformedData = transformAPISchemaSections(newData.allDepthOfInterest_DTO, depthLim);
  //               // console.log("transformedData:", transformedData)
  //               setSchemaSections(transformedData);
  //           }
  //       } catch (error) {
  //           console.error("Error fetching and updating schema sections:", error);
  //       }
  //   }
  // };

  //effect to update tabs based on schemaSections
  useEffect(() => {
    if (schemaSections.length > 0) {
      const transformedSections = schemaSections.map((section) => {
        return {
            layerName: section.layerName,
            sections: section.sections,
            storedInDB: section.storedInDB
        };
      });
      
      setTabs(transformedSections);

    }
    // console.log("scheeemmma section wellschema:", schemaSections)
    onSchemaUpdate(schemaSections);
  }, [schemaSections]);

  // Function to update the name of a tab
  const updateTabName = (tabIndex, newName) => {
    const updatedTabs = [...tabs];
    const updatedSchemaSections = [...schemaSections];

    updatedTabs[tabIndex].layerName = newName;
    updatedSchemaSections[tabIndex].layerName = newName;

    setTabs(updatedTabs);
    setSchemaSections(updatedSchemaSections);

    // Update the active tab if necessary
    if (activeKey === updatedTabs[tabIndex].layerName) {
      setActiveKey(newName);
    }
  };

  //handle submission of new layer name
  const handleNewLayerNameSubmit = () => {
    const lowercaseNewLayerName = newLayerName.trim().toLowerCase();

    if (getExistingLayerNames().some(name => name.toLowerCase() === lowercaseNewLayerName)) {
        alert("This layer name already exists!");
        return;
    }

    // Update the name of the currently active tab
    updateTabName(tabs.findIndex(tab => tab.layerName === activeKey), newLayerName);

    setNewLayerName('');
    setShowModal(false);

    // Set the active tab to the new name
    setActiveKey(newLayerName);
};

  //helper function to delete sections, called in handleDeleteTab
  const handleDelete = async (section) => {

    try {
        const response = await deleteDOI({
            site: wellAPI.siteName,
            well: wellAPI.wellName,
            doiID: section.doiID
        });
        // console.log('delete response',{ response });
        // console.log('deleting doiID:', section.doiID)
        // message.success('Layer deleted successfully');
        return true;
    } catch (error) {
        console.log({ error });
        // message.error('Error deleting Layer');
        return false;
    }
  };

  //handle deletion of all sections in a tab(layer)
  const handleDeleteTab = async (layerNameToDelete) => {
    // const layerName = tabs[deleteKey].layerName; // Use tabs for the name, since schemaSections may not have the new layer yet

    Modal.confirm({
      title: 'Confirm Deletion',
      content: `Are you sure you want to delete the tab for layer "${layerNameToDelete}"? This will delete all sections in this layer.`,
      async onOk() {
        let hasError = false;

        // Get the sections to delete from schemaSections based on the layerName
        const sectionsToDelete = schemaSections.find(item => item.layerName === layerNameToDelete)?.sections || [];

        // Loop through sections to delete and attempt to delete each one
        for (let section of sectionsToDelete) {
            hasError = hasError || !(await handleDelete(section));
        }

        if (!hasError) {
          // Update the tabs state based on the updated schemaSections
          const newTabs = tabs.filter(tab => tab.layerName !== layerNameToDelete);
          setTabs(newTabs);

          // Remove the corresponding schemaSection from the schemaSections state
          const newSchemaSections = schemaSections.filter(section => section.layerName !== layerNameToDelete);
          setSchemaSections(newSchemaSections);
          onSchemaUpdate(newSchemaSections);

          // Maybe set it to the first tab or a tab before the deleted tab, etc.
          setActiveKey(newTabs[0]?.layerName || '');
        }


        // Display success/error message
        hasError 
            ? message.error('Error deleting one or more sections.')
            : message.success('All sections deleted successfully');

        if (!hasError) {
              setDeleteTab(true); // set the flag if there's no error
        }

      },
    });
  };

  //callback function to handle adding new section to a tab(layer) when user creates a new section (needed for deleteing entire layers's sections)
  const handleUpdateSections = (layerName, updatedSections) => {
    
    // Find the index of the tab using the layerName to update tabs state
    const tabIndex = tabs.findIndex(tab => tab.layerName === layerName);
    if (tabIndex !== -1) {
        const updatedTabs = [...tabs];
        updatedTabs[tabIndex].sections = updatedSections;
        setTabs(updatedTabs);
    }

    // Update schemaSections
    const schemaIndex = schemaSections.findIndex(schema => schema.layerName === layerName);
    if (schemaIndex !== -1) {
        const updatedSchemaSections = [...schemaSections];
        updatedSchemaSections[schemaIndex].sections = updatedSections;
        setSchemaSections(updatedSchemaSections);
        onSchemaUpdate(updatedSchemaSections);
    }
  };
  
  return (
    <div>
      <Tabs 
        activeKey={activeKey}
        onChange={(key) => setActiveKey(key)}
        tabBarExtraContent={<Button onClick={handleAddTab}>New Layer</Button>}
        className="customTabs"
      >
        {tabs.map((tab) => (
          <TabPane
              key={tab.layerName}
              tab={
                  <div>
                    {tab.storedInDB ? (
                      <span style={{ marginRight: '8px', fontSize: 18 }}>{tab.layerName}</span>
                    ) : (
                      <Select
                          dropdownClassName="custom-select-dropdown"
                          value={tab.layerName}
                          onChange={(value) => handleTabNameChange(tab.layerName, value)}
                          style={{ width: '160px', marginRight: '8px' }}
                          placeholder="Select Layer Type"
                      >
                          <Option value="Add New">Add New</Option>
                          {availableLayers.map(layer => (
                              !getExistingLayerNames().includes(layer) && <Option key={layer} value={layer}>{layer}</Option>
                          ))}
                      </Select>
                    )}
                    <CloseOutlined onClick={() => handleDeleteTab(tab.layerName)} />
                  </div>
              }
          >
              {/* Render the WellSchematic directly */}
              <WellSchematicLayout 
                  initialSections={tab.sections || []} 
                  layerName={tab.layerName} 
                  onUpdate={onSchemaUpdate}
                  schemaSections={schemaSections}
                  updateTabs={() => setUpdateTab(tab.layerName)}
                  onAddNewSection={handleUpdateSections}
              />
          </TabPane>
       
        ))}
      </Tabs>
      <Modal
        title="Add New Layer Name"
        visible={showModal}
        onOk={handleNewLayerNameSubmit}
        onCancel={() => setShowModal(false)}
      >
        <input 
          value={newLayerName}
          onChange={(e) => setNewLayerName(e.target.value)}
          placeholder="Enter new layer name"
        />
      </Modal>
    </div>
  );
}

export default WellSchema;
