let colourCount = 0;

const colours = [
    //change these so that they are not so similar?
    
    "#005F73", // Teal
    "#1A6F75", // Slightly different Teal
    "#329B9E", // Slightly different Aquamarine
    "#D3B392", // Slightly different Tan
    "#FFAA00", // Slightly different Orange
    "#D97803", // Slightly different Dark Orange
    "#CC4E04", // Slightly different Reddish Orange
    "#B93015", // Slightly different Dark Red
    "#A82B28"  // Slightly different Burgundy

];

export const colourWheel = () => {
    colourCount += 1;

    if (colourCount >= colours.length) {
        colourCount = 0;
    }

    return colours[colourCount];
}

